const regExs = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^[A-Za-z0-9!@#$%^&*_-]{4,}$/,
  lettersOnly: /^[a-zA-Z ]*$/,
  cardHolder: /^(?!.*[.'’-]{2})(?=.*\b\w{2,}\b)([A-Za-zÀ-ÿ.'’-]{2,})(?:\s+[A-Za-zÀ-ÿ.'’-]{2,})+$/,
  cardCvc: /^\d{3,4}$/,
  zipMinLength: /[\w-]{4,}/,
  isNumeric: /^\d+$/,
};

export default regExs;
