import type { FC } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import { BrowserTracing, Replay } from '@sentry/browser';
import { reactRouterV6Instrumentation } from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import type { Logger } from '../modules/logger';
import LoggerFactory, { ConsoleEngine, SentryEngine } from '../modules/logger';
import { IS_DEV, NODE_ENV, SENTRY_DSN } from '../constants/envKeysMapper';

export const LoggerContext = createContext<Logger>({} as Logger);

interface IProps {
  children: React.ReactNode;
}

export const LoggerProvider: FC<IProps> = ({ children }) => {
  const [logger, _] = useState<Logger>(
    LoggerFactory({
      engines: [
        new ConsoleEngine(),
        new SentryEngine({
          normalizeDepth: 5,
          dsn: SENTRY_DSN,
          environment: NODE_ENV,
          integrations: [
            new BrowserTracing({
              routingInstrumentation: reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
              ),
            }),
            new Replay({ maskAllText: false, maskAllInputs: true }),
          ],
          tracesSampleRate: IS_DEV ? 1 : 0.2,
          replaysOnErrorSampleRate: 1,
          replaysSessionSampleRate: IS_DEV ? 1 : 0.1,
        }),
      ],
    })
  );

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};
