import React, { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DYNAMIC_ROUTE_PARAMS, PAGES } from '../../constants/pages';
import { IRoutes } from '../Offer';
import { IProductPageParams } from '../../HOCs/withProductGuard';

const v1 = React.lazy(() => import('./v1/index'));
const quiz = React.lazy(() => import('./quiz/index'));
const quiz2 = React.lazy(() => import('./quiz2/index'));

const routes: IRoutes = {
  v1,
  quiz,
  quiz2,
};

export interface ICancelPagesParams extends IProductPageParams {
  [DYNAMIC_ROUTE_PARAMS.VERSION]: string;
  [DYNAMIC_ROUTE_PARAMS.LOCALE]: string;
}

const CancelRouter = () => {
  const { version } = useParams<keyof ICancelPagesParams>() as ICancelPagesParams;

  const Page = routes[version] as FC;

  return <> {Page ? <Page /> : <Navigate to={PAGES.NOT_FOUND} replace />}</>;
};

export default CancelRouter;
